// Global imports
import React, { useState, useEffect } from 'react';
import { Button, Link, ButtonGroup } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import * as focusTrap from 'focus-trap';
import { useLocation } from 'react-use';
import { useKeycloak } from '../../utils/KeycloakContext';
import authClient from '../../utils/AuthClient';

// Local imports
import './timeoutModal.scss';

const TimeoutModal = ({ timeout, promptBeforeIdle }) => {
  const location = useLocation();
  const excludedRoutes = ['/tms/open-tasks', '/tms/closed-tasks'];
  const container = document.getElementById('modal');
  const trap = focusTrap.createFocusTrap('#modal', {
    onActivate: () => container.classList.add('is-active'),
    onDeactivate: () => container.classList.remove('is-active'),
  });

  const deactivate = document.getElementById('stillHere' || 'signOut');
  if (deactivate) {
    deactivate.addEventListener('click', trap.deactivate);
  }

  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  const client = authClient();

  useEffect(() => {
    trackPageView();
  }, []);

  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(timeout);

  const handleLogout = () => {
    client.logout();
  };

  const onIdle = () => {
    if (excludedRoutes.includes(location.pathname)) {
      return;
    }
    setOpen(false);
    handleLogout();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onAction = () => {
    // refresh token on user 'actions' debounced by 10 seconds.
    if (keycloak) {
      keycloak.updateToken().then(() => {
        // eslint-disable-next-line no-console
        console.log('timeout modal - token refreshed');
      });
    }
  };

  const onPrompt = () => {
    if (excludedRoutes.includes(location.pathname)) {
      return;
    }
    setOpen(true);
    trap.activate();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onActive,
    onAction,
    onIdle,
    onPrompt,
    promptBeforeIdle,
    timeout,
    // Debounce the onAction function by setting delay in milliseconds.
    debounce: 10_000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        const closeModal = document.getElementById('stillHere');
        if (closeModal) {
          closeModal.click();
        }
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open]);

  const logoutTimer =
    remaining > 59
      ? `${Math.floor(remaining / 60) + 1} minutes`
      : `${Math.floor(remaining / 20) * 20 + 20} seconds`;

  const handleStillHere = () => {
    activate();
    onActive();
    setOpen(false);

    // keycloak token refresh
    if (keycloak.isTokenExpired(10)) {
      keycloak.updateToken().then(() => {
        // eslint-disable-next-line no-console
        console.log('token refreshed via modal');
      });
    }
  };

  return (
    <div className="govuk-overlay" style={{ display: open ? 'flex' : 'none' }}>
      <div className="govuk-modal" id="modal" role="dialog" data-testid="Modal" aria-modal="true">
        <h2 className="govuk-heading-l">{t('pages.modal.title')}</h2>
        <p className="govuk-body" aria-live="polite" aria-atomic="true">
          <span aria-label={`${t('pages.modal.time-left')} ${logoutTimer}`}>
            {t('pages.modal.time-left')}
            <strong>{logoutTimer}</strong>
          </span>
        </p>
        <p className="govuk-body">{t('pages.modal.unsaved-progress')}</p>
        <ButtonGroup className="govuk-button-group">
          <Button
            className="govuk-button modal-button"
            role="button"
            id="stillHere"
            data-testid="stillHere"
            onClick={handleStillHere}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                handleStillHere();
              }
            }}
          >
            Continue on this page
          </Button>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className="sign-out-link"
            role="button"
            id="signOut"
            data-testid="signOut"
            onClick={handleLogout}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogout();
              }
            }}
            tabIndex={0}
          >
            Sign out
          </Link>
        </ButtonGroup>
      </div>
    </div>
  );
};

TimeoutModal.propTypes = {
  timeout: PropTypes.number.isRequired,
  promptBeforeIdle: PropTypes.number.isRequired,
};

export default TimeoutModal;
