import config from 'react-global-configuration';

const ITEM_OPTIONS = ['itemsOnAPerson', 'items', 'foundNothing'];
const CP_OPTIONS = {
  'roro freight accompanied': ['peopleHidingVehicle', 'unsecuredGoods'],
  'roro freight unaccompanied': ['peopleHidingTrailer', 'unsecuredGoods'],
  'roro tourist': ['peopleHidingVehicle'],
};
const SAMS_OPTIONS = ['sams', 'foundNothing'];
const RORO_OPTIONS = {
  'roro freight accompanied': ['roroItems', 'sams'],
  'roro freight unaccompanied': ['roroItems', 'sams'],
  'roro tourist': ['roroItems', 'roroItemsFootPassenger', 'sams'],
};
const RORO_NEGATIVE_OPTIONS = {
  'roro freight accompanied': ['foundNothing'],
  'roro freight unaccompanied': ['foundNothing'],
  'roro tourist': ['foundNothing'],
};

const decodeConfigObj = (configKey) => {
  const configVal = config.get(configKey);
  if (configVal === null) {
    return {};
  }
  try {
    const result = JSON.parse(configVal);
    return result;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Unable to parse config key ${configKey}, with a value of:`, configVal);
    return {};
  }
};

function toLowerCaseKeys(obj) {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value]));
}

const getOptionsForPortAndMode = (payload) => {
  const port = payload.port?.name.toLowerCase() || null;
  const mode = payload.modeOfTransport?.mode.toLowerCase() || null;
  if (!port || !mode) {
    return [];
  }
  let options = [];

  const itemPorts = toLowerCaseKeys(decodeConfigObj('eab2ItemPorts'));
  if (itemPorts[port] && itemPorts[port].includes(mode)) {
    options = options.concat(ITEM_OPTIONS);
  }

  const cpPorts = toLowerCaseKeys(decodeConfigObj('eab2CpPorts'));
  if (cpPorts[port] && cpPorts[port].includes(mode)) {
    options = options.concat(CP_OPTIONS[mode]);
  }

  const samsPorts = toLowerCaseKeys(decodeConfigObj('eab2SamsPorts'));
  if (samsPorts[port] && samsPorts[port].includes(mode)) {
    options = options.concat(SAMS_OPTIONS);
  }

  const roroPorts = toLowerCaseKeys(decodeConfigObj('eab2RoroPorts'));

  if (roroPorts[port] && roroPorts[port].includes(mode)) {
    options = options.concat(RORO_OPTIONS[mode]);
  }

  const roroNegativePorts = toLowerCaseKeys(decodeConfigObj('eab2RoroNegativePorts'));
  if (roroNegativePorts[port] && roroNegativePorts[port].includes(mode)) {
    options = options.concat(RORO_NEGATIVE_OPTIONS[mode]);
  }

  return options;
};

export default getOptionsForPortAndMode;
