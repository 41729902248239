/**
 * Function returns an error message if there is not exactly one haulier,
 * or undefined if there is exactly one haulier.
 * @param {string} fieldData - data related to the specific field being validated (not used in this function)
 * @param {object} validation - this parameter is not used in the function
 * @param {object} component - component definition
 * @param {object} data - form data for component containing company information
 * @returns {object|undefined} An error object if there is not exactly one haulier, undefined otherwise
 */

/* eslint-disable no-unused-vars */
const mustHaveOneHaulier = (fieldData, validation, component, data) => {
  const hauliers =
    data.companies?.filter((company) => company.type.toUpperCase() === 'HAULIER') || [];
  const haulierCount = hauliers.length;

  let msg = '';
  if (haulierCount === 0) {
    msg = 'You must add a haulier';
  } else if (haulierCount > 1) {
    msg = 'You cannot add more than one haulier';
  }

  return msg
    ? {
        error: msg,
        id: component.id,
      }
    : undefined;
};

export default mustHaveOneHaulier;
