import dayjs from 'dayjs';
import Ajv from 'ajv';

import Transform from './payloadTransformations';
import applyTransformations from './applyTransformations';
import posPostSchema from './schemas/EAB/point-of-seizure-post.schema.json';

/**
 * Cleans a JSON object by validating it against a JSON schema.
 * Removes any additional properties not defined in the schema.
 *
 * @param {Object} data - The JSON object to be validated and cleaned.
 * @param {Object} schema - The JSON Schema to validate against.
 * @returns {Object} The cleaned JSON object with only allowed fields.
 */
export const cleanJson = (data, schema) => {
  const ajv = new Ajv({
    removeAdditional: 'all',
  });
  const validator = ajv.compile(schema);
  if (!validator(data)) {
    // eslint-disable-next-line no-console
    console.error('❌EPMS Submit Validation Errors:');
    // eslint-disable-next-line no-console
    console.table(
      validator.errors.map((err) => ({
        Field: err.instancePath || '(root)',
        Message: err.message,
      }))
    );
  }
  return data;
};

export const baseTransformations = {
  businessKey: [{ action: Transform.Rename, to: 'fwin' }],
  centaurReference: [{ action: Transform.Rename, to: 'custodyRef' }],
  caseInstanceId: [{ action: Transform.Remove }],
  definitionId: [{ action: Transform.Remove }],
  doYouNeedToAddMoreItems: [{ action: Transform.Remove }],
  ended: [{ action: Transform.Remove }],
  epmsSubmitted: [{ action: Transform.Remove }],
  eventDateTime: [{ action: Transform.Spread }, { action: Transform.Remove }],
  // has to come after
  eventDate: [{ action: Transform.Rename, to: 'dateEntered' }, { action: Transform.Remove }],
  form: [{ action: Transform.Remove }],
  formStatus: [{ action: Transform.Remove }],
  howDoYouWantToAddItems: [{ action: Transform.Remove }],
  id: [{ action: Transform.Remove }],
  itemsActiveId: [{ action: Transform.Remove }],
  links: [{ action: Transform.Remove }],
  lockup: [
    { action: Transform.Map, fields: { value: 'value', description: 'label' } },
    { action: Transform.Rename, to: 'station' },
  ],
  modeOfTransport: [{ action: Transform.Remove }],
  officerEmails: [{ action: Transform.Remove }],
  operationOrExercise: [{ action: Transform.Remove }],
  operationOrExerciseName: [{ action: Transform.Rename, to: 'operation' }],
  port: [{ action: Transform.Remove }],
  showRemovalConfirmation: [{ action: Transform.Remove }],
  subLocation: [{ action: Transform.Remove }],
  submittingUsersEmail: [{ action: Transform.Remove }],
  submittingUsersName: [{ action: Transform.Remove }],
  suspended: [{ action: Transform.Remove }],
  tenantId: [{ action: Transform.Remove }],
  terminal: [{ action: Transform.Remove }],
  whatHappened: [{ action: Transform.Remove }],
  formInstanceId: [{ action: Transform.Remove }],
  itemsLastRemoved: [{ action: Transform.Remove }],
  flightDepartureDateTime: [{ action: Transform.Remove }],
  countryOfArrival: [{ action: Transform.Remove }],
  flightArrivalDateTimeNonUKDeparture: [{ action: Transform.Remove }],
  flightArrivalDateTimeUKDeparture: [{ action: Transform.Remove }],
  arrivalDateTime: [{ action: Transform.Remove }],
  departureDateTime: [{ action: Transform.Remove }],
  peopleFoundDateTime: [{ action: Transform.Remove }],
  photosUploadSpinner: [{ action: Transform.Remove }],
  photosNextPage: [{ action: Transform.Remove }],
  whoDecidedSelection: [{ action: Transform.Remove }],
  whyWasPassengerStoppedArray: [{ action: Transform.Remove }],
  controlStrategies: [{ action: Transform.Remove }],
  targetCategory: [{ action: Transform.Remove }],
  intelligenceReferenceNumber: [{ action: Transform.Remove }],
  riskTestingSelection: [{ action: Transform.Remove }],
  concealmentMethods: [{ action: Transform.Remove }],
  detectionTechnologies: [{ action: Transform.Remove }],
  hiddenNationalities: [{ action: Transform.Remove }],
  reasonsForSelection: [{ action: Transform.Remove }],
  reasonForSelectionTargetIndicator: [{ action: Transform.Remove }],
  reasonForSelectionTargetIndicatorExtra: [{ action: Transform.Remove }],
  peopleActiveId: [{ action: Transform.Remove }],
  people: [{ action: Transform.Remove }],
  searchTechniques: [{ action: Transform.Remove }],
  activePersonAdult: [{ action: Transform.Remove }],
};

export const itemTransformations = {
  willTheItemBeStored: [{ action: Transform.Remove }],
  subLocation: [{ action: Transform.Remove }],
  specialHandlingInstructions: [{ action: Transform.Remove }],
  hasAnotherAgencyAdoptedTheItem: [{ action: Transform.Remove }],
  doYouHavePhotosOfTheItemToAdd: [{ action: Transform.Remove }],
  validfrom: [{ action: Transform.Remove }],
  validto: [{ action: Transform.Remove }],
  id: [{ action: Transform.Remove }],
  meta: [{ action: Transform.Remove }],
  instructionsToThePropertyOfficer: [{ action: Transform.Rename, to: 'comments' }],
  giveAReason: [{ action: Transform.Rename, to: 'comments' }, { action: Transform.Remove }],
  sealNumber: [{ action: Transform.Rename, to: 'sealNo' }],
  'whatIsTheInitialMovement?': [
    { action: Transform.Map, fields: { value: 'epmscode', description: 'description' } },
    { action: Transform.Rename, to: 'movementReason' },
  ],
  epmsOfficerEmails: [{ action: Transform.Rename, from: 'value', to: 'oicEmail' }],
  itemCategory: [
    {
      action: Transform.Map,
      fields: { value: 'itemSubCategory.epmscategory', description: 'description', id: 'id' },
    },
    { action: Transform.Rename, to: 'category' },
  ],
  itemSubCategory: [
    {
      action: Transform.Map,
      fields: { category: 'epmscategory', value: 'epmscode', description: 'name' },
    },
    { action: Transform.Rename, to: 'subCategory' },
  ],
  exhibitReference: [{ action: Transform.Rename, to: 'exhibitNo' }],
  photos: [{ action: Transform.Rename, to: 'attachments' }],
  agencyReferenceCode: [{ action: Transform.Rename, to: 'soco' }],
  itemDescription: [{ action: Transform.Rename, to: 'description' }],
  specialStorageConditions: [
    { action: Transform.Map, fields: { value: 'epmsvalue', description: 'description' } },
    { action: Transform.Rename, to: 'storageCondition' },
  ],
  type: [{ action: Transform.Rename, to: 'specialHandling' }],
  storageLocation: [{ action: Transform.Map, fields: { value: 'value', description: 'label' } }],
  unitOfMeasure: [{ action: Transform.Map, fields: { description: 'unit', value: 'epmsid' } }],
  agency: [
    { action: Transform.Map, fields: { value: 'epmsagencyid', description: 'abbreviatedname' } },
  ],
  description: [{ action: Transform.Default, default: 'No description provided' }],
  constructedOrAdapted: [{ action: Transform.Rename, to: 'unusuallyConcealed' }],
  constructedDescription: [{ action: Transform.Rename, to: 'constructedOrAdaptedDescription' }],
  concealmentMethod: [
    {
      action: Transform.Consolidate,
      singleField: 'concealmentMethod',
      collection: 'concealmentMethods',
      subField: 'label',
    },
    { action: Transform.Remove },
  ],
  detectionTechnology: [
    {
      action: Transform.Consolidate,
      singleField: 'technology',
      collection: 'detectionTechnologies',
      subField: 'label',
      rename: 'detectionTechnology',
    },
  ],
  detectionTechnologies: [{ action: Transform.Remove }],
  searchTechnique: [
    {
      action: Transform.Consolidate,
      singleField: 'searchTechnique',
      collection: 'searchTechniques',
      subField: 'label',
    },
    { action: Transform.Remove },
  ],
  brandText: [{ action: Transform.Rename, to: 'model' }],
  fieldTestType: [{ action: Transform.Default }],
  confirmItemRemoval: [{ action: Transform.Remove }],
  photosUploadSpinner: [{ action: Transform.Remove }],
  seizedOrDetained: [{ action: Transform.Rename, to: 'entryClass' }, { action: Transform.Remove }],
  legalReasonForSeizing: [
    { action: Transform.Map, fields: { value: 'epmsvalue', description: 'reason' } },
    { action: Transform.Rename, to: 'reasonDetained' },
    { action: Transform.Remove },
  ],
  legalReasonForDetaining: [
    { action: Transform.Map, fields: { value: 'epmsvalue', description: 'reason' } },
    { action: Transform.Rename, to: 'reasonDetained' },
    { action: Transform.Remove },
  ],
};

// special operations for specific item types

export const categoryTransformations = {
  Vehicle: {
    colour: [{ action: Transform.Rename, to: 'color' }],
    vehicleIdentifiers: [
      {
        action: Transform.Map,
        fields: {
          serial1: 'registrationMark',
          serial2: 'chassisNumber',
          engineNumber: 'engineNumber',
        },
      },
      { action: Transform.Spread },
      { action: Transform.Remove },
    ],
    quantity: [{ action: Transform.Default, default: 1 }],
    anpr: [{ action: Transform.Default, default: 'N' }],
    unitOfMeasure: [
      { action: Transform.Map, fields: { description: 'unit', value: 'epmsid' } },
      { action: Transform.Default, default: { description: 'Number of items', value: '9' } },
    ],
  },
  Alcohol: {
    abvDetails: [{ action: Transform.Rename, from: 'abv', to: 'abv' }],
    abv: [
      {
        action: Transform.Parse,
        function: (abv) => {
          return parseFloat(abv) || 6.1;
        },
      },
    ],
  },
  'Financial instrument': {
    cashAmountDescription: [{ action: Transform.Default, default: 'Not provided' }],
    estimatedAmount: [{ action: Transform.Rename, to: 'cashAmount' }],
    typeOfCurrency: [
      { action: Transform.Map, fields: { description: 'currency', value: 'currencycode' } },
      { action: Transform.Rename, to: 'currency' },
    ],
    unitOfMeasure: [
      { action: Transform.Map, fields: { description: 'unit', value: 'epmsid' } },
      { action: Transform.Default, default: { description: 'Total Amount', value: '3' } },
    ],
    amountInPoundsSterling: [{ action: Transform.Rename, to: 'amountGBP' }],
    doYouBelieveThatThisItemIsLinkedToTerrorism: [
      { action: Transform.Rename, to: 'linkedToTerrorism' },
    ],
    quantity: [{ action: Transform.Default, default: 0 }],
    didTheBearerMakeADeclaration: [{ action: Transform.Rename, to: 'bearerDeclaration' }],
    hasAPenaltyPaymentBeenTaken: [{ action: Transform.Default }],
    hasAWarningLetterBeenIssued: [{ action: Transform.Default }],
  },
  Firearms: {
    brandText: [{ action: Transform.Rename, to: 'model' }],
    quantity: [{ action: Transform.Default, default: 1 }],
    unitOfMeasure: [
      { action: Transform.Map, fields: { description: 'unit', value: 'epmsid' } },
      { action: Transform.Default, default: { description: 'Number of items', value: '9' } },
    ],
  },
  'Indecent or obscene material': {
    typeOfIndecentOrObsceneMaterial: [{ action: Transform.Default }],
  },
  Weapons: {
    quantity: [{ action: Transform.Default, default: 1 }],
    unitOfMeasure: [
      { action: Transform.Map, fields: { description: 'unit', value: 'epmsid' } },
      { action: Transform.Default, default: { description: 'Number of items', value: '9' } },
    ],
  },
};

/**
 * Some ePMS fields require two-digit numbers like 02 when the matching ref data is simply 2.
 * This adds a 0 if needed, also useful for the event time which the user may enter a single number
 * for the hour or minute, but we need them with 0s before.
 * @param {number} number the number to conditionally put a 0 in front of
 * @returns either number or number with a zero in front of it
 */
const addZeroToNumber = (number) => {
  return number.length === 1 ? `0${number}` : number;
};

const convertDate = (date, time) => {
  const [day, month, year] = date.split('-');
  const [hour, minute] = time.split(':');
  return `${year}-${addZeroToNumber(month)}-${addZeroToNumber(day)}T${addZeroToNumber(
    hour
  )}:${addZeroToNumber(minute)}:00.000Z`;
};

/**
 * Build attachment objects at the item level using the metadata at the payload level
 * @param {object} item the item to update the attachments on
 * @param {object} meta the metadata to use for the updates
 * @returns item with a converted attachments array
 */
/* eslint-disable no-param-reassign */
const mapAttachments = (item, meta) => {
  const descriptionForAll = item.whatDoThePhotosShow
    ? item.whatDoThePhotosShow
        .map((description) => {
          return description.label;
        })
        .join(', ')
    : 'No description provided';
  delete item.whatDoThePhotosShow;
  return item.attachments?.map((photo) => {
    const matchingMeta = meta?.documents?.find((document) => document.name === photo.name);
    return {
      file: photo.name,
      imageViewDescription: { description: 'Whole Item', value: '8' },
      originalName: photo.name,
      size: matchingMeta?.size || 0,
      url: matchingMeta?.url || '',
      description: descriptionForAll,
    };
  });
};

/**
 * Update the item fields that have a more complex mapping than rename/move/delete
 * @param {object} item the payload object to update
 * @returns updated item
 */
/* eslint-disable no-param-reassign */
const updateItemFields = (item, leadOfficerEmail, payload) => {
  const { meta } = payload;
  // oicEmail defaults to leadOfficerEmail if the external agency did not adopt the item, or if the field was left blank
  if (item.didTheyAdopt === 'no' || !item.oicEmail) {
    item.oicEmail = leadOfficerEmail;
  }

  // If there is no agency then the value should be set to 3 which corresponds to 'Not adopted'
  if (!item.agency || Object.entries(item.agency).length === 0 || item.didTheyAdopt === 'no') {
    item.agency = { description: 'Not Adopted', value: '3' };
  }

  if (item.typeOfIndecentOrObsceneMaterial) {
    item.typeOfIndecentOrObsceneMaterial = item.typeOfIndecentOrObsceneMaterial.toString();
  }

  // RegExp to remove non-numeric characters except '.' and parse
  const itemQuantity = item.quantity.toString();
  item.quantity = parseFloat(itemQuantity.replace(/[^\d.]/g, ''));

  if (item.quantity > 999999999.99) {
    item.quantity = 999999999.99;
  }

  // If item quantity is more than 1 and currently has more than 2 decimal places
  const decimalPlaces = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  };

  if (item.quantity > 0 && decimalPlaces(item.quantity) > 2) {
    item.quantity = Math.round((item.quantity + Number.EPSILON) * 100) / 100;
  }

  // map model to brand name
  item.model = item.model || item.otherBrand || item.brand?.name;
  delete item.brand;
  delete item.otherBrand;

  if (item.movementReason) {
    item.movementReason.value = addZeroToNumber(item.movementReason.value);
  }

  if (item.attachments) {
    item.attachments = mapAttachments(item, meta);
  } else {
    // Covers case where user adds description but changes mind and does not upload photos
    delete item.whatDoThePhotosShow;
  }

  // Map disposal instruction if item should be destroyed
  if (item.shouldThisItemBeDestroyed === 'yes') {
    item.disposalInstruction = { value: '2', description: 'Destruction' };
  }
  delete item.shouldThisItemBeDestroyed;

  if (item.specialHandling) {
    item.specialHandling = item.specialHandling.map((sh) => {
      return {
        value: sh.epmsvalue.toString(),
        description: sh.description,
      };
    });
  }

  if (item.itemHolders) {
    if (item.itemHolders[0]?.value === 'all') {
      item.itemHolders = payload.people.map((p) => {
        return { uuid: p.id };
      });
    } else {
      item.itemHolders = item.itemHolders.map((sh) => {
        return {
          uuid: sh.id,
        };
      });
    }
  }

  if (item.detectionTechnology) {
    item.detectionTechnology.forEach((dt, index, arr) => {
      arr[index].outcome = dt.technology === 'none' ? 'negative' : 'positive';
    });
  }

  // CAC and PRD are the financial instrument subcategories handled as generic items so should
  // not have a cashAmountDescription
  if (item.subCategory.category === 'CAC' || item.subCategory.category === 'PRD') {
    delete item.cashAmountDescription;
  }

  if (item.subCategory.category === 'ALP') {
    delete item.abv;
  }

  item.copItemCategoryId = parseInt(item.category?.id, 10);
  delete item.category?.id;

  // Only required untill EAB-1553 is complete, backend expects booleans for these new fields already while we still collect strings
  // This converts 'yes'/'no' values to booleans
  if (item.linkedToTerrorism) {
    item.linkedToTerrorism = item.linkedToTerrorism === 'yes';
  }
  if (item.unusuallyConcealed) {
    item.unusuallyConcealed = item.unusuallyConcealed === 'yes';
  }

  if (item.description) {
    // eslint-disable-next-line no-control-regex
    item.description = item.description.replace(new RegExp('[\r\n]', 'gm'), '; ');
  }

  item.entryClass = {
    description: `${item.entryClass?.charAt(0).toUpperCase() + item.entryClass?.slice(1)}`,
    value: item.entryClass?.charAt(0).toUpperCase(),
  };

  if (item.reasonDetained) {
    item.reasonDetained.value = addZeroToNumber(item.reasonDetained.value);
  }

  // Delete ANPR value if the vehicle type is not one of the following
  if (!['VGD', 'VPS', 'VQB', 'VSP'].includes(item.category.value)) {
    delete item.anpr;
  }
  return item;
};

const getItemTransformations = (item) => {
  const category = item?.itemCategory?.category ?? 'default';
  return categoryTransformations[category]
    ? { ...itemTransformations, ...categoryTransformations[category] }
    : itemTransformations;
};

export const transformPayload = (payload) => {
  const transformedPayload = applyTransformations(payload, baseTransformations);
  transformedPayload.seizedDate = convertDate(
    transformedPayload.dateEntered,
    transformedPayload.eventTime
  );
  transformedPayload.dateEntered = dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
  delete transformedPayload.eventTime;

  transformedPayload.obiEmail = payload.submittingUsersEmail;

  // remove all acitiveID,
  Object.keys(transformedPayload).forEach((key) => {
    if (key.endsWith('ActiveId')) {
      delete transformedPayload[key];
    }
  });

  const transformedPayloadItems = payload.items
    ?.map((item) => {
      if (!item.itemSubCategory) {
        return null;
      }
      const transformations = getItemTransformations(item);
      if (item.agency) {
        item.copAgencyCopRefDataId = item.agency.id;
      }
      if (item.brand) {
        item.brandCopRefDataId = item.brand.id;
      }
      if (item.abvDetails?.abvNotKnown) {
        item.abvNotKnown = true;
      }

      const transformedItem = applyTransformations(item, transformations);
      updateItemFields(transformedItem, transformedPayload.leadOfficerEmail.value, payload);
      return transformedItem;
    })
    .filter((i) => !!i);

  transformedPayload.inputItems = transformedPayloadItems;
  delete transformedPayload.items;

  delete transformedPayload.leadOfficerEmail;
  delete transformedPayload.meta;

  return cleanJson(
    {
      businessKey: payload.businessKey,
      inputEntries: [transformedPayload],
      system: 'COP',
      submittingUsersEmail: payload.submittingUsersEmail,
      submittingUsersName: payload.submittingUsersName,
    },
    posPostSchema
  );
};
